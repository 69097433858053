import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link, graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import "css/project.scss";

const Project = ({ data }) => {
  const post = data.markdownRemark;
  let HeaderMedia;

  if (post.frontmatter.header) {
    HeaderMedia =
        <Img
          style={{margin: '20px auto'}}
          fluid={post.frontmatter.header.childImageSharp.fluid} />;

  } else if (post.frontmatter.headerURL) {
    HeaderMedia =
        <div
            style={{
                display: 'flex', flexDirection: 'column',
                width: '100%', height: '30vh',
                margin: '20px 0',
            }}>
          <iframe
            title={post.frontmatter.title}
            src={post.frontmatter.headerURL}
            style={{flexGrow: 1, height: '30vh'}}
            allow="autoplay; fullscreen">
          </iframe>
        </div>;
  }

  return (
    <Layout>
      <Box>
        <h1>{post.frontmatter.title}</h1>
        <em style={{marginTop: 0}}>
          {post.frontmatter.keywords}
        </em>

        {HeaderMedia}

        <div dangerouslySetInnerHTML={{ __html: post.html }} />

        <Link
          style={{fontWeight: 100, marginTop: '2rem', display: 'inline-block', marginLeft: '-16px'}}
          to="/">
          ← Back
        </Link>
      </Box>
    </Layout>
  );
};

Project.propTypes = {
  data: PropTypes.object,
};

export default Project;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        keywords
        headerURL
        header {
          childImageSharp {
            fluid(maxWidth: 750, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
